// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// eslint-disable-next-line prettier/prettier
import {
  BillingStatus,
  FillSubscriptionPlansActionPayload,
  ISubscriptionCoupon,
  SubPlansCode,
  SubscriptionState,
} from './typedefs';

const initialState: SubscriptionState = {
  isFetching: false,
  isSubscribing: false,
  isCheckout: false,
  isSubscriptionCancelFetching: false,
  isSubscriptionsFetching: false,
  subscriptionPlans: [],
  billingStatus: null,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    startSubscriptionCancelFetching(state) {
      state.isSubscriptionCancelFetching = true;
    },
    stopSubscriptionCancelFetching(state) {
      state.isSubscriptionCancelFetching = false;
    },
    isSubscriptionsFetched(state) {
      state.isSubscriptionsFetching = true;
    },
    fillSubscriptionPlans(
      state,
      action: PayloadAction<{
        plans: FillSubscriptionPlansActionPayload;
        coupon: ISubscriptionCoupon;
      }>,
    ) {
      if (action.payload.coupon) {
        const { coupon } = action.payload;
        state.subscriptionPlans = action.payload.plans.map((plan) => {
          if (plan.code === SubPlansCode.free) {
            return {
              ...plan,
              name: coupon.subscription_type.name,
              plan_limit: coupon.subscription_type.plan_limit,
              plan_metametric_limit:
                coupon.subscription_type.plan_metametric_limit,
              plan_storyteller_limit:
                coupon.subscription_type.plan_storyteller_limit,
              plan_storyteller_prompt_image_limit:
                coupon.subscription_type.plan_storyteller_prompt_image_limit,
              plan_storyteller_prompt_limit:
                coupon.subscription_type.plan_storyteller_prompt_limit,
              plan_visualize_minute_limit:
                coupon.subscription_type.plan_visualize_minute_limit,
              text_line_1: coupon.subscription_type.text_line_1,
              text_line_2: coupon.subscription_type.text_line_2,
              text_line_3: coupon.subscription_type.text_line_3,
              text_line_4: coupon.subscription_type.text_line_4,
              text_line_5: coupon.subscription_type.text_line_5,
              header: coupon.subscription_type.header,
            };
          }

          return plan;
        });
      } else {
        state.subscriptionPlans = action.payload.plans;
      }
    },
    setBillingStatus(state, action: PayloadAction<BillingStatus | null>) {
      state.billingStatus = action.payload;
    },
    setSubscribing(state, action: PayloadAction<boolean>) {
      state.isSubscribing = action.payload;
    },
    setCheckout(state, action: PayloadAction<boolean>) {
      state.isCheckout = action.payload;
    },
    // INJECT
  },
});

export default subscriptionSlice;
