import { Theme } from '@mui/material/styles';

export const styles = {
  orderTokens: {
    padding: '20px 20px 24px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    mb: '40px',
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    position: 'relative',
  },
  input: (theme: Theme) => ({
    fontFamily: 'Montserrat, sans-serif!important',
    fontWeight: 400,
    fontSize: '14px!important',
    borderRadius: '16px!important',
    background: `${theme.palette.common.surface['surface 5']}!important`,
    color: theme.palette.common.brand,
    height: 'initial!important',
  }),
  inputWrapper: (theme: Theme) => ({
    height: '40px',
    borderRadius: '16px',
    background: theme.palette.common.surface['surface 2'],
    border: `1px solid ${theme.palette.divider}`,
    paddingLeft: '8px',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: theme.palette.common.surface['surface 3'],
      border: `1px solid ${theme.palette.divider}`,
    },
  }),
  inputWrapperError: (theme: Theme) => ({
    border: `1px solid ${theme.palette.error.main}`,
  }),
  buttonWrapper: {
    position: 'relative',
  },
  error: {
    position: 'absolute',
    bottom: '-20px',
    left: 0,
  },
};
