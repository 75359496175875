import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { ModalTypes } from '@core/Modal/types';

import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionActions } from '@bus/subscription/actions';
import { getIsSubscribing } from '@bus/subscription/selectors';
import { useBraintreePayment } from '../../../hooks/useBraintreePayment';

type SubscribeProps = {
  planId: string;
  currentPlanId: string;
};

export const Subscribe: React.FC<
  ModalTypes.ModalComponentProps<SubscribeProps>
> = ({ planId, currentPlanId }) => {
  const dispatch = useDispatch();

  const isSubscribing = useSelector(getIsSubscribing);

  const { isFormLoading, errorMessage, fieldError, processPayment } =
    useBraintreePayment();

  const handlePayment = async () => {
    if (currentPlanId) {
      await processPayment(() => {
        dispatch(
          subscriptionActions.braintreeUpdateSubscribe({
            plan_id: planId,
          }),
        );
      });
    } else {
      await processPayment((nonce) => {
        dispatch(
          subscriptionActions.braintreeSubscribe({
            payment_method_nonce: nonce,
            plan_id: planId,
          }),
        );
      });
    }
  };

  return (
    <Box sx={styles.subscribe}>
      <Box width={'100%'}>
        <Box sx={styles.formWrapper}>
          <Box sx={styles.fieldWrapper}>
            <Typography component={'label'} htmlFor={'card-number'}>
              Card Number
            </Typography>
            <Box
              id={'card-number'}
              className={'hosted-field'}
              height={'40px'}
              sx={[
                styles.inputWrapper,
                fieldError['number'] ? styles.inputWrapperError : {},
              ]}
            />
            {fieldError['number'] && (
              <Typography
                variant={'caption'}
                color={'error.main'}
                sx={styles.error}>
                {fieldError['number']}
              </Typography>
            )}
          </Box>

          <Box sx={styles.fieldWrapper}>
            <Typography component={'label'} htmlFor={'expiration-date'}>
              Expiration Date
            </Typography>
            <Box
              id={'expiration-date'}
              className={'hosted-field'}
              sx={[
                styles.inputWrapper,
                fieldError['expirationDate'] ? styles.inputWrapperError : {},
              ]}
            />
            {fieldError['expirationDate'] && (
              <Typography
                variant={'caption'}
                color={'error.main'}
                sx={styles.error}>
                {fieldError['expirationDate']}
              </Typography>
            )}
          </Box>

          <Box sx={styles.fieldWrapper}>
            <Typography component={'label'} htmlFor={'cvv'}>
              CVV
            </Typography>
            <Box
              id={'cvv'}
              className={'hosted-field'}
              sx={[
                styles.inputWrapper,
                fieldError['cvv'] ? styles.inputWrapperError : {},
              ]}
            />
            {fieldError['cvv'] && (
              <Typography
                variant={'caption'}
                color={'error.main'}
                sx={styles.error}>
                {fieldError['cvv']}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={styles.buttonWrapper}>
          <Button
            onClick={handlePayment}
            disabled={isSubscribing || isFormLoading}
            variant={'primary'}
            size={'40'}
            fullWidth>
            <Typography variant={'body1'}>
              {isSubscribing || isFormLoading ? 'Loading...' : 'Pay'}
            </Typography>
          </Button>
          {errorMessage && (
            <Typography
              variant={'caption'}
              color={'error.main'}
              sx={styles.error}>
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Subscribe;
