// @ts-nocheck
import { call } from 'redux-saga/effects';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { handleMessageBilling } from '@bus/ws/saga/workers/handleMessageBilling';
import { handleOrder } from '@bus/ws/saga/workers/handleOrder';
import { IResponseBillingMessage, WSAction, WSEvent } from '@bus/ws/typedefs';
import { fetchProfile } from '@bus/profile/saga/workers';

export function* handleResponseBilling(payload: IResponseBillingMessage) {
  try {
    if (
      payload.action === WSAction.response &&
      payload.event === WSEvent.billing_subscription_activated
    ) {
      yield call(handleMessageBilling, { payload });
    }
    if (
      payload.action === WSAction.response &&
      payload.event === WSEvent.billing_subscription_updated
    ) {
      yield call(handleMessageBilling, { payload });
    }
    if (
      payload.action === WSAction.response &&
      payload.event === WSEvent.billing_subscription_cancelled
    ) {
      yield call(fetchProfile);
    }
    if (
      payload.action === WSAction.response &&
      payload.event === WSEvent.checkout_order_approved
    ) {
      yield call(handleOrder, { payload });
    }
  } catch (e) {
    yield handleError(e);
  }
}
