import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { subscriptionActions } from '../../actions';
import { BraintreeCheckoutActionPayload } from '../../typedefs';

export function* braintreeCheckout({
  payload,
}: PayloadAction<BraintreeCheckoutActionPayload>) {
  try {
    yield effects.put(subscriptionActions.setCheckout(true));
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.post, [
      { endpoint: 'payments/brain_tree/payment/', body },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(subscriptionActions.setCheckout(false));
  }
}
