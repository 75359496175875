import * as effects from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from '@REST/api';
import { handleError } from '@bus/ui/saga/workers/handleError';
import { throwError } from '@bus/ui/saga/workers/throwError';

import { subscriptionActions } from '../../actions';
import { BraintreeUpdateSubscribeActionPayload } from '../../typedefs';

export function* braintreeUpdateSubscribe({
  payload,
}: PayloadAction<BraintreeUpdateSubscribeActionPayload>) {
  try {
    yield effects.put(subscriptionActions.startFetching());
    const body: string = yield effects.call(JSON.stringify, payload);
    const response: Response = yield effects.apply(api, api.put, [
      { endpoint: 'payments/brain_tree/subscription/', body },
    ]);
    if (!response.ok) {
      yield throwError(response);
    }
  } catch (e) {
    yield handleError(e);
  } finally {
    yield effects.put(subscriptionActions.stopFetching());
  }
}
