import React, { FC } from 'react';

import {
  Box,
  IconButton,
  ModalProps,
  Typography,
  useTheme,
} from '@mui/material';

import { styles } from './styles';
import Close from '@components/icons/Close';
import Search from '@components/icons/Search';
import { modalActions } from '@core/Modal/state/actions';
import { useDispatch } from 'react-redux';

type DrawerHeaderProps = {
  onClose?: ModalProps['onClose'];
  name: string;
  icon: React.ReactNode;
  isBorder?: boolean;
  isSearch?: boolean;
};

export const DrawerHeader: FC<DrawerHeaderProps> = ({
  onClose,
  name,
  icon,
  isBorder = true,
  isSearch = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleClick = (event: React.KeyboardEvent | React.MouseEvent) => {
    onClose?.(event, 'backdropClick');
  };

  const handleSearch = () => {
    dispatch(
      modalActions.modal({
        component: 'SearchChat',
        onClose,
        paperStyles: {
          maxWidth: '864px',
          width: '100%',
          maxHeight: '527px',
          height: '100%',
        },
        variant: 'fullMobile',
      }),
    );
  };

  return (
    <Box
      sx={[
        styles.drawerHeader,
        { borderBottom: isBorder ? `1px solid ${theme.palette.divider}` : 0 },
      ]}>
      <Box sx={styles.wrapper}>
        {icon}
        <Typography variant={'body1'} color={'text.secondary'}>
          {name}
        </Typography>
      </Box>
      <Box sx={styles.buttonWrapper}>
        {isSearch && (
          <IconButton onClick={handleSearch}>
            <Search
              fontSize={'small'}
              htmlColor={theme.palette.common.surface['surface 47']}
            />
          </IconButton>
        )}
        <IconButton onClick={handleClick}>
          <Close
            fontSize={'small'}
            htmlColor={theme.palette.common.surface['surface 47']}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DrawerHeader;
