import { useEffect, useState } from 'react';
import { HostedFields } from 'braintree-web/hosted-fields';
import { handleErrors } from '@helpers/handleErrors';
import braintree from 'braintree-web';
import { styles } from '@components/modals/Subscribe/styles';
import { useTheme } from '@mui/material';

export const useBraintreePayment = () => {
  const theme = useTheme();
  const [hostedFieldsInstance, setHostedFieldsInstance] =
    useState<HostedFields | null>(null);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldError, setFieldError] = useState({
    number: '',
    cvv: '',
    expirationDate: '',
  });

  // ✅ Braintree initialization
  useEffect(() => {
    braintree.client
      .create({
        authorization: process.env
          .REACT_APP_BRAINTREE_TOKENIZATION_KEY as string,
      })
      .then((clientInstance) => {
        return braintree.hostedFields.create({
          client: clientInstance,
          styles: {
            input: {
              ...styles.input(theme),
            },
            '.valid': { color: 'green' },
            '.invalid': { color: 'red' },
          },
          fields: {
            number: {
              selector: '#card-number',
              placeholder: '**** **** **** ****',
            },
            cvv: { selector: '#cvv', placeholder: '123' },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: 'MM/YY',
            },
          },
        });
      })
      .then((hostedFields) => {
        setHostedFieldsInstance(hostedFields);
        setIsFormLoading(false);

        hostedFields.on('validityChange', (event) => {
          const newErrors = { ...fieldError };

          Object.keys(event.fields).forEach((field) => {
            const key = field as keyof typeof event.fields;

            if (!event.fields[key].isValid && !event.fields[key].isEmpty) {
              newErrors[
                field as keyof typeof fieldError
              ] = `${key} is not valid`;
            }

            if (event.fields[key].isValid) {
              newErrors[field as keyof typeof fieldError] = '';
            }
          });

          setFieldError(newErrors);
        });
      })
      .catch((err) => {
        handleErrors(err);
        setErrorMessage('Failed to load the payment form.');
      });
  }, []);

  // ✅ Payment error handler
  const handlePaymentErrors = (tokenizeErr: any) => {
    switch (tokenizeErr.code) {
      case 'HOSTED_FIELDS_FIELDS_EMPTY':
        setErrorMessage('All fields are empty! Please fill out the form.');
        setFieldError({
          number: 'This field is required',
          cvv: 'This field is required',
          expirationDate: 'This field is required',
        });
        break;
      case 'HOSTED_FIELDS_FIELDS_INVALID':
        setErrorMessage('Some fields are invalid.');
        break;
      case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
        setErrorMessage('Tokenization failed. Please check card details.');
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
        setErrorMessage('Network error occurred when tokenizing.');
        break;
      default:
        handleErrors(tokenizeErr);
        setErrorMessage('Something went wrong.');
    }
  };

  // ✅ Function for processing payments
  const processPayment = async (onSuccess: (nonce: string) => void) => {
    if (!hostedFieldsInstance) {
      setErrorMessage('The form is still loading. Please wait.');

      return;
    }

    setErrorMessage('');

    try {
      const payload = await hostedFieldsInstance.tokenize();
      onSuccess(payload.nonce);
    } catch (err: any) {
      handlePaymentErrors(err);
    }
  };

  return {
    isFormLoading,
    errorMessage,
    fieldError,
    processPayment,
  };
};
