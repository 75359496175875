import { createAction } from '@reduxjs/toolkit';

import subscriptionSlice from './slice';
import prepareActions from '@helpers/prepareActions';

export const subscriptionActions = {
  ...subscriptionSlice.actions,
  fetchSubscriptionPlans: createAction('subscription/fetchSubscriptionPlans'),
  subscriptionsDetail: createAction('subscription/subscriptionsDetail'),
  cancelSubscription: createAction(
    'subscription/cancelSubscription',
    prepareActions.movePromiseToMeta,
  ),
  intervalDetail: createAction('subscription/intervalDetail'),
  createSubscription: createAction(
    'subscription/createSubscription',
    (payload: any) => {
      return { payload };
    },
  ),
  closeSubscription: createAction('subscription/closeSubscription'),
  braintreeSubscribe: createAction(
    'subscription/braintreeSubscribe',
    (payload: any) => {
      // modify payload place
      return { payload };
    },
  ),
  braintreeCheckout: createAction(
    'subscription/braintreeCheckout',
    (payload: any) => {
      // modify payload place
      return { payload };
    },
  ),
  braintreeUpdateSubscribe: createAction(
    'subscription/braintreeUpdateSubscribe',
    (payload: any) => {
      // modify payload place
      return { payload };
    },
  ),
  // INJECT
};
